import * as React from 'react';
import Layout from "../components/layout";
import {graphql} from "gatsby";
import DetailPage from "../components/detailPage";

const StreetPage = ({data}) => {
    return (
        <Layout>
            <h1 className={'text-9xl mb-6'}>Street</h1>
            <DetailPage data={data} />
        </Layout>
    )
}

export default StreetPage

export const query = graphql`
    query {
        all: allFile(
            filter: {
                extension: {regex: "/(jpg)|(jpeg)/"},
                relativeDirectory: {eq: "photography/street"}
            }
            sort: {order: DESC, fields: modifiedTime}
        ) {
            nodes {
                childImageSharp {
                    gatsbyImageData(width: 1600, placeholder: BLURRED)
                    fluid {
                        src
                        originalName
                    }
                    resize {
                        aspectRatio
                    }
                    fields {
                        exif {
                            raw {
                                image {
                                    Make
                                    Model
                                }
                                exif {
                                    FNumber
                                    FocalLengthIn35mmFormat
                                    ISO
                                    LensMake
                                    LensModel
                                    ShutterSpeedValue
                                    FocalLength
                                }
                            }
                        }
                    }
                }
                relativeDirectory
            }
        }
    }
`;

export const Head = () => <title>Street - Rol John Torralba</title>
